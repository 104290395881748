import { ComponentRef, ViewContainerRef } from '@angular/core';
import AccountEditorWindowUiComponent from '@iupics-components/specific/window/account-editor-window-ui/account-editor-window-ui.component';
import AccountingViewerUiComponent from '@iupics-components/specific/window/accounting-viewer-ui/accounting-viewer-ui.component';
import AccountingViewerWindowUiComponent from '@iupics-components/specific/window/accounting-viewer-window-ui/accounting-viewer-window-ui.component';
import BpartnerBalanceComponent from '@iupics-components/specific/window/bpartner-balance/bpartner-balance.component';
import ChangelogWindowUIComponent from '@iupics-components/specific/window/changelog-window-ui/changelog-window-ui.component';
import CreateFromInvoiceWindowUiComponent from '@iupics-components/specific/window/create-from-invoice-window-ui/create-from-invoice-window-ui.component';
import CreateFromOpenInvoiceComponent from '@iupics-components/specific/window/create-from-open-invoice/create-from-open-invoice.component';
import CreateFromShipmentWindowUiComponent from '@iupics-components/specific/window/create-from-shipment-window-ui/create-from-shipment-window-ui.component';
import CreateFromStatementWindowUiComponent from '@iupics-components/specific/window/create-from-statement-window-ui/create-from-statement-window-ui.component';
import CardsUiComponent from '@iupics-components/specific/window/customer-360/components/cards/cards-ui/cards-ui.component';
import Customer360Component from '@iupics-components/specific/window/customer-360/customer-360.component';
import DirectPrintingDocumentsComponent from '@iupics-components/specific/window/direct-printing-documents/direct-printing-documents.component';
import FastCreateInvoiceVendorComponent from '@iupics-components/specific/window/fast-create-invoice-vendor/fast-create-invoice-vendor.component';
import FastCreateOrderComponent from '@iupics-components/specific/window/fast-create-order/fast-create-order.component';
import ImportFileLoaderWindowUiComponent from '@iupics-components/specific/window/import-file-loader-window-ui/import-file-loader-window-ui.component';
import InvoiceMatchingWindowImplComponent from '@iupics-components/specific/window/invoice-matching-window-impl/invoice-matching-window-impl.component';
import { LocationMigrationComponent } from '@iupics-components/specific/window/location-migration/location-migration.component';
import LocationPanelComponent from '@iupics-components/specific/window/location-panel/location-panel.component';
import LoginasFormComponent from '@iupics-components/specific/window/loginas-form/loginas-form.component';
import ManufacturingResourceChartComponent from '@iupics-components/specific/window/manufacturing-resource-chart-ui/manufacturing-resource-chart-ui.component';
import MatchingAcctViewerWindowUiComponent from '@iupics-components/specific/window/matching-acct-viewer-window-ui/matching-acct-viewer-window-ui.component';
import MaterialTransactionComponent from '@iupics-components/specific/window/material-transaction/material-transaction.component';
import OperationManagementUIComponent from '@iupics-components/specific/window/operation-management-ui/operation-management-ui.component';
import PartnerViewComponent from '@iupics-components/specific/window/partner-view/partner-view.component';
import PaymentAllocationWindowUiComponent from '@iupics-components/specific/window/payment-allocation-window-ui/payment-allocation-window-ui.component';
import PaymentSelectionWindowUiComponent from '@iupics-components/specific/window/payment-selection-window-ui/payment-selection-window-ui.component';
import PlanningWindowUiComponent from '@iupics-components/specific/window/planning-window-ui/planning-window-ui.component';
import PosUiComponent from '@iupics-components/specific/window/pos-ui/pos-ui.component';
import PriceHistoryComponent from '@iupics-components/specific/window/price-history/price-history.component';
import ProcessInProgressWindowUiComponent from '@iupics-components/specific/window/process-in-progress-window-ui/process-in-progress-window-ui.component';
import ProductAttributeComponent from '@iupics-components/specific/window/product-attribute/product-attribute.component';
import ProductViewComponent from '@iupics-components/specific/window/product-view/product-view.component';
import SelectOrderComponent from '@iupics-components/specific/window/select-order/select-order.component';
import SpecificWindowUiComponent from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { StorekeeperWindowUiComponent } from '@iupics-components/specific/window/storekeeper-window-ui/storekeeper-window-ui.component';
import TreeMaintenanceUIComponent from '@iupics-components/specific/window/tree-maintenance-ui/tree-maintenance-ui.component';
import Vendor360Component from '@iupics-components/specific/window/vendor360/vendor360.component';
import WidgetEditorUiComponent from '@iupics-components/specific/window/widget-editor-ui/widget-editor-ui.component';
import AutocompleteUiComponent from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import CalendarUiComponent from '@iupics-components/standard/fields/calendar-ui/calendar-ui.component';
import CommentUiComponent from '@iupics-components/standard/fields/comment-ui/comment-ui.component';
import InputColorUiComponent from '@iupics-components/standard/fields/input-color-ui/input-color-ui.component';
import InputFileUiComponent from '@iupics-components/standard/fields/input-file-ui/input-file-ui.component';
import InputImageUiComponent from '@iupics-components/standard/fields/input-image-ui/input-image-ui.component';
import InputLocationUiComponent from '@iupics-components/standard/fields/input-location-ui/input-location-ui.component';
import InputNumberUiComponent from '@iupics-components/standard/fields/input-number-ui/input-number-ui.component';
import InputProductAttributesUiComponent from '@iupics-components/standard/fields/input-product-attributes-ui/input-product-attributes-ui.component';
import InputSwitchUiComponent from '@iupics-components/standard/fields/input-switch-ui/input-switch-ui.component';
import InputTextHtmlUiComponent from '@iupics-components/standard/fields/input-text-html-ui/input-text-html-ui.component';
import InputTextUiComponent from '@iupics-components/standard/fields/input-text-ui/input-text-ui.component';
import InputTextareaUiComponent from '@iupics-components/standard/fields/input-textarea-ui/input-textarea-ui.component';
import InputTimeUiComponent from '@iupics-components/standard/fields/input-time-ui/input-time-ui.component';
import StepperUiComponent from '@iupics-components/standard/fields/stepper-ui/stepper-ui.component';
import GridViewUiComponent from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import InputJsonLineUiComponent from '@iupics-components/standard/input-json-line-ui/input-json-line-ui.component';
import InputJsonUiComponent from '@iupics-components/standard/input-json-ui/input-json-ui.component';
import AccordionUiComponent from '@iupics-components/standard/layouts/accordion-ui/accordion-ui.component';
import AdditionalInfoUiComponent from '@iupics-components/standard/layouts/additional-info-ui/additional-info-ui.component';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import EditViewUiComponent from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import GridUiComponent from '@iupics-components/standard/layouts/grid-ui/grid-ui.component';
import RowUiComponent from '@iupics-components/standard/layouts/row-ui/row-ui.component';
import MenuBarDetailUiComponent from '@iupics-components/standard/menu/menu-bar-detail-ui/menu-bar-detail-ui.component';
import NotesPanelUiComponent from '@iupics-components/standard/notes/notes-panel-ui/notes-panel-ui.component';
import ValuePreferencePanelComponent from '@iupics-components/standard/value-preference-panel/value-preference-panel.component';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import ChartWidgetComponent from '@iupics/modules/iupics-widgets/components/chart-widget/chart-widget.component';
import CountListWidgetUiComponent from '@iupics/modules/iupics-widgets/components/count-list-widget/count-list-widget-ui.component';
import GridWidgetComponent from '@iupics/modules/iupics-widgets/components/grid-widget/grid-widget.component';
import { MetabaseWidgetComponent } from '@iupics/modules/iupics-widgets/components/metabase-widget/metabase-widget.component';
import RecentItemWidgetUiComponent from '@iupics/modules/iupics-widgets/components/recent-item-widget-ui/recent-item-widget-ui.component';
import {BpartnerSearchComponent} from "@iupics-components/specific/window/bpartner-search/bpartner-search.component";
import {
  CreateAffiliationComponent
} from "@iupics-components/specific/window/create-affiliation/create-affiliation.component";
import {CreatePartnerComponent} from "@iupics-components/specific/window/create-partner/create-partner.component";
import {
  CreateUpdateOwnerComponent
} from "@iupics-components/specific/window/create-update-owner/create-update-owner.component";
import {
  EthiasManagementComponent
} from "@iupics-components/specific/window/ethias-management/ethias-management.component";
import {
  GenererAttestationFiscaleComponent
} from "@iupics-components/specific/window/generer-attestation-fiscale/generer-attestation-fiscale.component";
import {
  GetMapInfoCampComponent
} from "@iupics-components/specific/window/get-map-info-camp/get-map-info-camp.component";
import {GpsProjectComponent} from "@iupics-components/specific/window/gps-project/gps-project.component";
import {GpsUnitComponent} from "@iupics-components/specific/window/gps-unit/gps-unit.component";
import {EventSearchComponent} from "@iupics-components/specific/window/event-search/event-search.component";
import {InscribeUiComponent} from "@iupics-components/specific/window/inscribe-ui/inscribe-ui.component";
import {
  InscribeWaitingListComponent
} from "@iupics-components/specific/window/inscribe-waiting-list/inscribe-waiting-list.component";
import {
  InscriptionPostEvenementComponent
} from "@iupics-components/specific/window/inscription-post-evenement/inscription-post-evenement.component";
import {
  LettrageEcrituresComptablesComponent
} from "@iupics-components/specific/window/lettrage-ecritures-comptables/lettrage-ecritures-comptables.component";
import {
  MassComplemInsuranceComponent
} from "@iupics-components/specific/window/mass-complem-insurance/mass-complem-insurance.component";
import {OneManagementComponent} from "@iupics-components/specific/window/one-management/one-management.component";
import {
  ParticipantManagementComponent
} from "@iupics-components/specific/window/participant-management/participant-management.component";
import {PassageMasseComponent} from "@iupics-components/specific/window/passage-masse/passage-masse.component";
import {PostRejectedComponent} from "@iupics-components/specific/window/post-rejected/post-rejected.component";
import {
  PreinscriptionCampComponent
} from "@iupics-components/specific/window/preinscription-camp/preinscription-camp.component";
import {ReadCreateStepComponent} from "@iupics-components/specific/window/read-create-step/read-create-step.component";
import {
  RemiseBuchettesAnfComponent
} from "@iupics-components/specific/window/remise-buchettes-anf/remise-buchettes-anf.component";
import {
  RemiseBuchettesMembreComponent
} from "@iupics-components/specific/window/remise-buchettes-membre/remise-buchettes-membre.component";
import {
  SimpleFileUploadComponent
} from "@iupics-components/specific/window/simple-file-upload/simple-file-upload.component";
import {StaffManagementComponent} from "@iupics-components/specific/window/staff-management/staff-management.component";
import {
  SubscriptionManagementComponent
} from "@iupics-components/specific/window/subscription-management/subscription-management.component";
import {
  SuppressionFonctionErroneeComponent
} from "@iupics-components/specific/window/suppression-fonction-erronee/suppression-fonction-erronee.component";
import {
  TransfertListeAttenteProcessComponent
} from "@iupics-components/specific/window/transfert-liste-attente-process/transfert-liste-attente-process.component";
import {
  TransfertParticipantComponent
} from "@iupics-components/specific/window/transfert-participant/transfert-participant.component";
import {UnitManagementComponent} from "@iupics-components/specific/window/unit-management/unit-management.component";
import { InputPlaceAutocompleteUiComponent } from '@iupics-components/standard/fields/input-place-autocomplete-ui/input-place-autocomplete-ui.component';

export function loadComponentFactory() {
  // #START CUSTO-GCB #170302
  CacheManagerService.iupics_components.set('BpartnerSearchComponent', BpartnerSearchComponent);
  CacheManagerService.iupics_components.set('CreateAffiliationComponent', CreateAffiliationComponent);
  CacheManagerService.iupics_components.set('CreatePartnerComponent', CreatePartnerComponent);
  CacheManagerService.iupics_components.set('CreateUpdateOwnerComponent', CreateUpdateOwnerComponent);
  CacheManagerService.iupics_components.set('EthiasManagementComponent', EthiasManagementComponent);
  CacheManagerService.iupics_components.set('GenererAttestationFiscaleComponent', GenererAttestationFiscaleComponent);
  CacheManagerService.iupics_components.set('GetMapInfoCampComponent', GetMapInfoCampComponent);
  CacheManagerService.iupics_components.set('GpsProjectComponent', GpsProjectComponent);
  CacheManagerService.iupics_components.set('GpsUnitComponent', GpsUnitComponent);
  CacheManagerService.iupics_components.set('EventSearchComponent', EventSearchComponent);
  CacheManagerService.iupics_components.set('InscribeWaitingListComponent', InscribeWaitingListComponent);
  CacheManagerService.iupics_components.set('InscriptionPostEvenementComponent', InscriptionPostEvenementComponent);
  CacheManagerService.iupics_components.set('LettrageEcrituresComptablesComponent', LettrageEcrituresComptablesComponent);
  CacheManagerService.iupics_components.set('MassComplemInsuranceComponent', MassComplemInsuranceComponent);
  CacheManagerService.iupics_components.set('OneManagementComponent', OneManagementComponent);
  CacheManagerService.iupics_components.set('ParticipantManagementComponent', ParticipantManagementComponent);
  CacheManagerService.iupics_components.set('PassageMasseComponent', PassageMasseComponent);
  CacheManagerService.iupics_components.set('PostRejectedComponent', PostRejectedComponent);
  CacheManagerService.iupics_components.set('PreinscriptionCampComponent', PreinscriptionCampComponent);
  CacheManagerService.iupics_components.set('ReadCreateStepComponent', ReadCreateStepComponent);
  CacheManagerService.iupics_components.set('RemiseBuchettesAnfComponent', RemiseBuchettesAnfComponent);
  CacheManagerService.iupics_components.set('RemiseBuchettesMembreComponent', RemiseBuchettesMembreComponent);
  CacheManagerService.iupics_components.set('SimpleFileUploadComponent', SimpleFileUploadComponent);
  CacheManagerService.iupics_components.set('StaffManagementComponent', StaffManagementComponent);
  CacheManagerService.iupics_components.set('SubscriptionManagementComponent', SubscriptionManagementComponent);
  CacheManagerService.iupics_components.set('SuppressionFonctionErroneeComponent', SuppressionFonctionErroneeComponent);
  CacheManagerService.iupics_components.set('TransfertListeAttenteProcessComponent', TransfertListeAttenteProcessComponent);
  CacheManagerService.iupics_components.set('TransfertParticipantComponent', TransfertParticipantComponent);
  CacheManagerService.iupics_components.set('UnitManagementComponent', UnitManagementComponent);
  CacheManagerService.iupics_components.set('InputPlaceAutocompleteUiComponent', InputPlaceAutocompleteUiComponent);
  // #END CUSTO-GCB #170302

  CacheManagerService.iupics_components.set('AccordionUiComponent', AccordionUiComponent);
  CacheManagerService.iupics_components.set('AdditionalInfoUiComponent', AdditionalInfoUiComponent);
  CacheManagerService.iupics_components.set('AutocompleteUiComponent', AutocompleteUiComponent);
  CacheManagerService.iupics_components.set('ButtonUiComponent', ButtonUiComponent);
  CacheManagerService.iupics_components.set('CalendarUiComponent', CalendarUiComponent);
  CacheManagerService.iupics_components.set('InputJsonUiComponent', InputJsonUiComponent);
  CacheManagerService.iupics_components.set('InputColorUiComponent', InputColorUiComponent);
  CacheManagerService.iupics_components.set('InputJsonLineUiComponent', InputJsonLineUiComponent);
  CacheManagerService.iupics_components.set('CommentUiComponent', CommentUiComponent);
  CacheManagerService.iupics_components.set('EditTabUiComponent', EditTabUiComponent);
  CacheManagerService.iupics_components.set('EditViewUiComponent', EditViewUiComponent);
  CacheManagerService.iupics_components.set('GridUiComponent', GridUiComponent);
  CacheManagerService.iupics_components.set('GridViewUiComponent', GridViewUiComponent);
  CacheManagerService.iupics_components.set('InputFileUiComponent', InputFileUiComponent);
  CacheManagerService.iupics_components.set('InputImageUiComponent', InputImageUiComponent);
  CacheManagerService.iupics_components.set('InputLocationUiComponent', InputLocationUiComponent);
  CacheManagerService.iupics_components.set('InputNumberUiComponent', InputNumberUiComponent);
  CacheManagerService.iupics_components.set('InputProductAttributesUiComponent', InputProductAttributesUiComponent);
  CacheManagerService.iupics_components.set('InputSwitchUiComponent', InputSwitchUiComponent);
  CacheManagerService.iupics_components.set('InputTextareaUiComponent', InputTextareaUiComponent);
  CacheManagerService.iupics_components.set('InputTextUiComponent', InputTextUiComponent);
  CacheManagerService.iupics_components.set('InputTimeUiComponent', InputTimeUiComponent);
  CacheManagerService.iupics_components.set('MenuBarDetailUiComponent', MenuBarDetailUiComponent);
  CacheManagerService.iupics_components.set('RowUiComponent', RowUiComponent);
  CacheManagerService.iupics_components.set('SelectOrderComponent', SelectOrderComponent);
  CacheManagerService.iupics_components.set('StepperUiComponent', StepperUiComponent);
  CacheManagerService.iupics_components.set('ValuePreferencePanelComponent', ValuePreferencePanelComponent);
  CacheManagerService.iupics_components.set('InputTextHtmlUiComponent', InputTextHtmlUiComponent);
  CacheManagerService.iupics_components.set('CardsUiComponent', CardsUiComponent);
  CacheManagerService.iupics_components.set('NotesPanelUiComponent', NotesPanelUiComponent);

  CacheManagerService.iupics_specific_window.set('default', SpecificWindowUiComponent);
  CacheManagerService.iupics_specific_window.set('BpartnerSearchComponent', BpartnerSearchComponent);
  CacheManagerService.iupics_specific_window.set('CreatePartnerComponent', CreatePartnerComponent);
  CacheManagerService.iupics_specific_window.set('AccountEditorWindowUiComponent', AccountEditorWindowUiComponent);
  CacheManagerService.iupics_specific_window.set('AccountingViewerUiComponent', AccountingViewerUiComponent);
  CacheManagerService.iupics_specific_window.set('EventSearchComponent', EventSearchComponent);
  CacheManagerService.iupics_specific_window.set('InscribeUiComponent', InscribeUiComponent);
  CacheManagerService.iupics_specific_window.set(
    'AccountingViewerWindowUiComponent',
    AccountingViewerWindowUiComponent
  );
  CacheManagerService.iupics_specific_window.set('BpartnerBalanceComponent', BpartnerBalanceComponent);
  CacheManagerService.iupics_specific_window.set(
    'CreateFromInvoiceWindowUiComponent',
    CreateFromInvoiceWindowUiComponent
  );
  CacheManagerService.iupics_specific_window.set('CreateFromOpenInvoiceComponent', CreateFromOpenInvoiceComponent);
  CacheManagerService.iupics_specific_window.set(
    'CreateFromShipmentWindowUiComponent',
    CreateFromShipmentWindowUiComponent
  );
  CacheManagerService.iupics_specific_window.set(
    'CreateFromStatementWindowUiComponent',
    CreateFromStatementWindowUiComponent
  );
  CacheManagerService.iupics_specific_window.set('FastCreateOrderComponent', FastCreateOrderComponent);
  CacheManagerService.iupics_specific_window.set('DirectPrintingDocumentsComponent', DirectPrintingDocumentsComponent);
  CacheManagerService.iupics_specific_window.set(
    'ImportFileLoaderWindowUiComponent',
    ImportFileLoaderWindowUiComponent
  );
  CacheManagerService.iupics_specific_window.set(
    'InvoiceMatchingWindowImplComponent',
    InvoiceMatchingWindowImplComponent
  );
  CacheManagerService.iupics_specific_window.set(
    'MatchingAcctViewerWindowUiComponent',
    MatchingAcctViewerWindowUiComponent
  );
  CacheManagerService.iupics_specific_window.set(
    'PaymentAllocationWindowUiComponent',
    PaymentAllocationWindowUiComponent
  );
  CacheManagerService.iupics_specific_window.set(
    'PaymentSelectionWindowUiComponent',
    PaymentSelectionWindowUiComponent
  );
  CacheManagerService.iupics_specific_window.set('PosUiComponent', PosUiComponent);
  CacheManagerService.iupics_specific_window.set('PriceHistoryComponent', PriceHistoryComponent);
  CacheManagerService.iupics_specific_window.set(
    'ProcessInProgressWindowUiComponent',
    ProcessInProgressWindowUiComponent
  );
  CacheManagerService.iupics_specific_window.set('ProductAttributeComponent', ProductAttributeComponent);
  CacheManagerService.iupics_specific_window.set('SpecificWindowUiComponent', SpecificWindowUiComponent);
  CacheManagerService.iupics_specific_window.set('OperationManagementUIComponent', OperationManagementUIComponent);
  CacheManagerService.iupics_specific_window.set('ProductViewComponent', ProductViewComponent);
  CacheManagerService.iupics_specific_window.set('ChangelogWindowUIComponent', ChangelogWindowUIComponent);
  CacheManagerService.iupics_specific_window.set('LocationPanelComponent', LocationPanelComponent);
  CacheManagerService.iupics_specific_window.set('TreeMaintenanceUIComponent', TreeMaintenanceUIComponent);
  CacheManagerService.iupics_specific_window.set('LoginasFormComponent', LoginasFormComponent);
  CacheManagerService.iupics_specific_window.set('PlanningWindowUiComponent', PlanningWindowUiComponent);
  CacheManagerService.iupics_specific_window.set('ManufacturingResourceChartComponent', ManufacturingResourceChartComponent);
  CacheManagerService.iupics_specific_window.set('FastCreateInvoiceVendorComponent', FastCreateInvoiceVendorComponent);
  CacheManagerService.iupics_specific_window.set('PartnerViewComponent', PartnerViewComponent);
  CacheManagerService.iupics_specific_window.set('MaterialTransactionComponent', MaterialTransactionComponent);
  CacheManagerService.iupics_specific_window.set('Customer360Component', Customer360Component);
  CacheManagerService.iupics_specific_window.set('Vendor360Component', Vendor360Component);
  CacheManagerService.iupics_specific_window.set('LocationMigrationComponent', LocationMigrationComponent);
  CacheManagerService.iupics_specific_window.set('WidgetEditorUiComponent', WidgetEditorUiComponent);
  CacheManagerService.iupics_specific_window.set('StorekeeperWindowUiComponent', StorekeeperWindowUiComponent);

  CacheManagerService.iupics_widgets.set('RecentItemWidgetUiComponent', RecentItemWidgetUiComponent);
  CacheManagerService.iupics_widgets.set('CountListWidgetUiComponent', CountListWidgetUiComponent);
  CacheManagerService.iupics_widgets.set('ChartWidgetComponent', ChartWidgetComponent);
  CacheManagerService.iupics_widgets.set('GridWidgetComponent', GridWidgetComponent);
  CacheManagerService.iupics_widgets.set('MetabaseWidgetComponent', MetabaseWidgetComponent);
}

export function createComponent<T>(vcr: ViewContainerRef, componentName: string): ComponentRef<T> {
  const component =
    CacheManagerService.iupics_components.get(componentName) ??
    CacheManagerService.iupics_specific_window.get(componentName) ??
    CacheManagerService.iupics_widgets.get(componentName);
  return vcr.createComponent<T>(component);
}
