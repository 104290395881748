import { AsyncPipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation, forwardRef, inject, output } from '@angular/core';
import { TagItem } from '@compiere-ws/models/tag-json';
import { TagService } from '@compiere-ws/services/tag/tag.service';
import { TranslateModule } from '@ngx-translate/core';
import { map } from 'rxjs';
import AutocompleteUiComponent from '../fields/autocomplete-ui/autocomplete-ui.component';
import {
  MultiLevelAutocompleteOutputData,
  MultiLevelAutocompleteUiComponent,
} from '../fields/multi-level-autocomplete-ui/multi-level-autocomplete-ui.component';

@Component({
  selector: 'iu-tags-editor-ui',
  templateUrl: './tags-editor-ui.component.html',
  styleUrls: ['./tags-editor-ui.component.scss'],
  standalone: true,
  imports: [forwardRef(() => AutocompleteUiComponent), TranslateModule, AsyncPipe, MultiLevelAutocompleteUiComponent],
  encapsulation: ViewEncapsulation.None,
})
export class TagsEditorUiComponent implements OnInit {
  tagService = inject(TagService);

  @ViewChild('autocomplete', { static: true })
  autoComplete: AutocompleteUiComponent;
  tagInput = '';

  @Input()
  tags: TagItem[] = [];

  tagsChange = output<TagItem[]>();

  suggestions = this.#getSuggestionsObs();

  ngOnInit(): void {
    if (this.tags == null) {
      this.tags = [];
    }
  }

  #getSuggestionsObs() {
    return this.tagService.getTags().pipe(
      map((s) => {
        const suggestions = [];
        for (const t of s) {
          suggestions.push({
            groupName: t.name,
            items: t.values.map((v) => ({ id: t.name + ' = ' + v, displayValue: v, name: t.name, value: v })),
          });
        }
        return suggestions;
      })
    );
  }

  onSelectionChanged(state: MultiLevelAutocompleteOutputData) {
    this.tagsChange.emit(<TagItem[]>state.current);
  }
}
