import {NgClass, NgIf} from '@angular/common';
import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { POTable } from '@compiere-ws/models/po.models';
import { ButtonLevel } from '@iupics-components/models/custom-types';
import EditViewUiComponent from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsEvent, IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
// #START CUSTO-GCB #170302
import { CompiereDataGridFilterType, DataStore, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { DocServerService } from '@compiere-ws/services/doc-server/doc-server.service';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { InfoDialogType } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import { AppConfig } from '@iupics-config/app.config';
import { MongoSearchQueryOperator } from '@iupics-manager/models/mongo-search';
// #END CUSTO-GCB #170302

@Component({
  selector: 'iu-button-ui',
  templateUrl: './button-ui.component.html',
  styleUrls: ['./button-ui.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
    imports: [TooltipModule, ButtonModule, NgClass, NgIf],
})
export default class ButtonUiComponent extends AbstractDataContainer implements OnInit, OnDestroy {
  #messageManager = inject(MessageManagerService);

  @ViewChild('btn', { read: ElementRef, static: true }) btnElt: ElementRef<HTMLButtonElement>;
  @Input() icon = '';
  @Input() btnType: ButtonLevel;
  private listener: Function;

  itemData: DynamicComponent;

  private tableName: string;
  private isSOTrx: boolean;
  @Input()
  channelId;
  @Input()
  columnName: string;

  @HostBinding('class.is-field') isFieldClass = false;
// #START CUSTO-GCB #170302
  docserverService = inject(DocServerService);
  #config = inject(AppConfig);
  needToShowMessage = false;
  messageToShow;
  nbUploadedFiles = 0;
  isUploadFile = false;
  simpleFileUploadFormId;
// #END CUSTO-GCB #170302
  ngOnInit() {
    // #START CUSTO-GCB #170302
    this.simpleFileUploadFormId = this.#config.getConstant('simpleFileUploadFormId') ?? Global.simpleFileUploadFormId;
    // #END CUSTO-GCB #170302
    super.ngOnInit();
    this.cssGrid = this.cssClass;
    if (this.fieldValue instanceof Object && this.fieldValue.displayValue) {
      this.label = this.fieldValue.displayValue;
    }
    // Ajout des events sur le bouton
    if (this.itemData) {
      // #START CUSTO-GCB #170302
      if (
          ['Fiche_partenaire', 'Z_Upload_File'].includes(this.data.columnName) &&
          this.simpleFileUploadFormId == this.data['formId']
      ) {
        this.isUploadFile = true;
        this.updateNbFileAttached();
      }
      // #END CUSTO-GCB #170302
      let parent: any = <EditViewUiComponent>(
        this.container.DOMChildrenComponent.find(
          (DOMChild: any) => DOMChild instanceof EditViewUiComponent && DOMChild.tabId === this.data.tabId
        )
      );
      parent = parent ?? this.editViewParent ?? this.container;
      if (this.itemData.processId) {
        this.data['processId'] = this.itemData.processId;
      // #START CUSTO-GCB #170302
        this.listener = this.renderer.listen(
            this.btnElt.nativeElement,
            'click',
            //#region custo
            ($event: MouseEvent) => {
                // Custo Scout : Message de confirmation si on souhaite clotûrer un camp. #119190
                if (
                    this.data &&
                    this.data.columnName === 'Processing' &&
                    this.data.tableName === 'C_Project' &&
                    parent &&
                    parent.editTabs &&
                    parent.editTabs.length > 0 &&
                    parent.editTabs[0].dataStored.data &&
                    parent.editTabs[0].dataStored.data['C_ProjectType_ID'] &&
                    parent.editTabs[0].dataStored.data['C_ProjectType_ID'].id === 1000016
                ) {
                    if (this.messageToShow) {
                        Global.infoDialog.message = {
                            summary: '',
                            detail: this.messageToShow
                        };
                        Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
                        Global.infoDialog.showInfoDialog();
                        const valSubscr = Global.infoDialog.confirm.subscribe((event) => {
                            $event.stopPropagation();
                            parent.updateModalDisplay(
                                {
                                    key: 'displayProcessUI',
                                    value: true,
                                    sourceComponent: this
                                },
                                {key: 'processId', value: this.data['processId']}
                            );
                            valSubscr.unsubscribe();
                        });
                    } else {
                        const messageRequest: DataStoreRequest = {
                            windowId: null,
                            parent_constraint: '',
                            compiereRequest: {
                                startRow: 0,
                                endRow: 0,
                                tableName: 'AD_Message',
                                filterModel: {
                                    VALUE: {
                                        filterType: CompiereDataGridFilterType.SET,
                                        values: ['SCOUTS_CLOSE_PORJECT_CONFIRMATION_MSG'],
                                        operators: [OperatorFilterType.EQUALS]
                                    }
                                }
                            }
                        };
                        this.store.getDataGrid(messageRequest).subscribe((message) => {
                            if (message && message.data && message.data.length > 0) {
                                this.messageToShow = message.data[0]['MSGTEXT'];
                                Global.infoDialog.message = {
                                    summary: '',
                                    detail: message.data[0]['MSGTEXT']
                                };
                                Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
                                Global.infoDialog.showInfoDialog();
                                const valSubscr = Global.infoDialog.confirm.subscribe((event) => {
                                    $event.stopPropagation();
                                    parent.updateModalDisplay(
                                        {
                                            key: 'displayProcessUI',
                                            value: true,
                                            sourceComponent: this
                                        },
                                        {key: 'processId', value: this.data['processId']}
                                    );
                                    valSubscr.unsubscribe();
                                });
                            }
                        });
                    }
                } else {
                    $event.stopPropagation();
                    parent.updateModalDisplay(
                        {key: 'displayProcessUI', value: true, sourceComponent: this},
                        {key: 'processId', value: this.data['processId']}
                    );
                }
            })
      // #END CUSTO-GCB #170302
      } else if (this.itemData.formId) {
        //TODO à commenter si on ne veut pas charger les données d'une form pour affichage instantanné au clic.
        if (!Global.isMobile()) {
          this.subscriptions.push(this.uiCreatorService.getSpecificWindow(this.itemData.formId).subscribe());
        }
        this.data['formId'] = this.itemData.formId;
        this.listener = this.renderer.listen(this.btnElt.nativeElement, 'click', ($event: MouseEvent) => {
          $event.stopPropagation();
          parent.updateModalDisplay(
            { key: 'displayFormUI', value: true, sourceComponent: this },
            { key: 'formId', value: this.data['formId'] }
          );
        });
      }
    }
    this.isFieldClass = this.editViewParent ? true : false;
  }

  onChildUpdate(event: IupicsEvent) {}
  onSiblingUpdate(event: IupicsEvent) {}
  onRemoveComponent(event: IupicsEvent) {}
    // #START CUSTO-GCB #170302
    setNewData(dataStored: DataStore) {
        super.setNewData(dataStored);
        this.updateNbFileAttached();
    }
    updateNbFileAttached() {
        if (
            this.isUploadFile &&
            this.dataStored &&
            this.dataStored.key &&
            this.parentTab &&
            this.parentTab.data &&
            this.parentTab.data.AD_Table_ID
        ) {
            const recordId = this.dataStored.key.recordId.split(',');
            const filterFromKey = this.docserverService.createfilterFromKey(this.dataStored.key);

            let samples: any[] = [
                {
                    ...filterFromKey,
                    'META|table_id': this.parentTab.data.AD_Table_ID,
                    'META|record_id': recordId.length > 1 ? parseInt(recordId[1], 10) : -1,
                    'META|column_name': this.data.columnName,
                    'META|AD_Org_ID': this.dataStored.data['AD_Org_ID'] ? this.dataStored.data['AD_Org_ID'].id : -1
                }
            ];
            const sub = this.docserverService.getNbDocuments(samples, MongoSearchQueryOperator.OR).subscribe((nb) => {
                this.nbUploadedFiles = nb;
                sub.unsubscribe();
            });
        }
    }
    // #END CUSTO-GCB #170302
  private initZoom() {
    if (this.dataStored.data.AD_Table_ID !== undefined && this.dataStored.data.AD_Table_ID !== null) {
      this.subscriptions.push(
        this.po.get<POTable>('AD_Table', this.dataStored.data.AD_Table_ID.id).subscribe((table) => {
          this.tableName = table.TableName;
          if (this.dataStored.data.IsSOTrx !== undefined) {
            this.isSOTrx = this.dataStored.data.IsSOTrx === 'Y';
          } else {
            this.isSOTrx = undefined;
          }
          if (this.listener !== undefined) {
            this.listener();
            this.listener = undefined;
          }

          this.listener = this.renderer.listen(this.btnElt.nativeElement, 'click', ($event: MouseEvent) => {
            $event.stopPropagation();
            this.subscriptions.push(
              this.uiCreatorService
                .zoomAcross(this.tableName, this.tableName + '_ID', this.dataStored.data.Record_ID, this.isSOTrx)
                .subscribe({
                  next: (dataWs) => {
                    if (dataWs && dataWs.length > 0) {
                      this.zoomInfo = {
                        windowId: dataWs[0].Window_ID,
                        dataUUID: dataWs[dataWs.length - 1]['Record_ID'],
                        record_id: this.dataStored.data.Record_ID,
                        children: dataWs.length > 1 ? dataWs.splice(0, dataWs.length - 1) : null,
                      };
                      if (this.data.isParam) {
                        this.isZoom = false;
                        Global.workspace.openTargetSearch({
                          zoomInfo: this.zoomInfo,
                          cat: { id: parseInt(dataWs[0].Window_ID, 10) },
                          source: { id: this.dataStored.data.Record_ID !== -1 ? dataWs[0].Record_ID : 'newRecord' },
                        });
                      } else {
                        const windowId = parseInt(dataWs[dataWs.length - 1]['Window_ID'], 10);
                        this.subscriptions.push(
                          this.uiCreatorService.getWindow(windowId).subscribe((tabUI) => {
                            const item: DynamicComponent = {
                              container: this.container,
                              DOMParentComponent: this.container,
                              linkedComponents: [this],
                              component: 'EditViewUiComponent',
                              cssClass: 'iupics-blade-content',
                              isCssOnComponent: false,
                              tabId: tabUI.tabId,
                              windowId: windowId,
                              zoomInfo: this.zoomInfo,
                            };
                            this.componentEmitter.emit({
                              type: IupicsTypeEvent.showEditView,
                              item: item,
                            });
                          })
                        );
                      }
                    }
                  },
                  error: (err) => {
                    this.isZoom = false;
                  },
                })
            );
          });
        })
      );
    } else {
      this.listener = this.renderer.listen(this.btnElt.nativeElement, 'click', ($event: MouseEvent) => {
        this.#messageManager.newMessage(new IupicsMessage('Error', 'No record id linked'));
      });
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.listener !== undefined) {
      this.listener();
    }
  }

  changeFieldValue(dataStored: DataStore, fromOtherChange: boolean = false, calloutStack: string[] = []) {
    super.changeFieldValue(dataStored, fromOtherChange, calloutStack);

    if (this.fieldValue instanceof Object && this.fieldValue.displayValue) {
      this.label = this.fieldValue.displayValue;
    }
    if (this.data.columnName === 'Record_ID') {
      this.initZoom();
    }
  }
}
